import { createReducer } from '@reduxjs/toolkit'
import { setPriceData } from './actions'
import { Price } from '../types'

export interface PriceState {
  data: Price[]
}

const inintSate: PriceState = {
  data: [],
}

export default createReducer(inintSate, (builder) => {
  builder.addCase(setPriceData, (state, action) => {
    let livePriceData: Price[] = action.payload
    if (livePriceData && livePriceData.length) {
      livePriceData = livePriceData.map((p) => {
        const stateData = state.data.find((f) => f.name === p.name)
        return { ...stateData, ...p }
      })
    }
    state.data = livePriceData
  })
})
